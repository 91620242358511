

















import Vue, { VueConstructor } from "vue";
import { mapState } from "vuex";
import { SectionMeta } from "@/js/data-types";

interface Computed {
  sections: SectionMeta[];
}
export default (Vue as VueConstructor<Vue & Computed>).extend({
  computed: {
    ...mapState(["sections"]),
    sectionOrder(): SectionMeta[] {
      return this.sections.slice().sort((a: SectionMeta, b: SectionMeta) => {
        const am: number = a.nav && a.nav.sort ? a.nav.sort : 0;
        const bm: number = b.nav && b.nav.sort ? b.nav.sort : 0;
        return bm - am;
      });
    }
  },
  mounted() {
    document.body.classList.add("no-scroll");
  },
  beforeDestroy() {
    document.body.classList.remove("no-scroll");
  },
  methods: {
    getURL(item: SectionMeta): string {
      return item.nav && item.nav.link ? item.nav.link : `#${item.id}`;
    },
    onClick(evt: Event, item: SectionMeta) {
      evt.preventDefault();
      const url = this.getURL(item);
      const el = document.querySelector(url);
      if (el) {
        let curr: HTMLElement = el as HTMLElement;
        let pos: number = 0;
        while (curr) {
          pos += curr.offsetTop;
          curr = curr.offsetParent as HTMLElement;
        }
        // if ("scrollBehavior" in document.documentElement.style) {
        //   console.log("Has smooth");

        window.scrollTo({ behavior: "smooth", top: pos - 50 });
        // } else {
        //   console.log("Has not smooth");
        // window.scrollTo(0, pos - 50);
        // }
      } else {
        window.location.href = url;
      }
      this.onClose();
    },
    onClose() {
      this.$store.commit("setNavOpen", false);
    }
  }
});
